<template>
<div class="log">
	<div class="login">
		<div class="login-width">
			<div class="login-center-width">
				<div class="login-center-width-img">
          <Isvg icon-class="logo-text" class-name="item-logo"/>
        </div>
				<div class="identity-center-width-goMoney" @click="selectIdentity(1)">
          <i class="iconfont icon-personal font-24"/>
          去赚钱
				</div>
				<div class="identity-center-width-goMoney nbottom" @click="selectIdentity(2)">
          <i class="iconfont icon-hirer font-24"/>
          成为雇主
				</div>
				<div class="b60"></div>
        <div class="b60"></div>
			</div>
		</div>
		<p class="register-beihao">© 2022 gogowork.cn All Rights Reserved 京IPC备：2021019219号-1</p>
	</div>
</div>
</template>

<script>
export default {
	name: 'identity',
	created() {
		if (!this.$store.getters["useUserStore/getToken"]) {
			this.$router.push('/register');
		}
	},
	methods: {
		selectIdentity(identity) {
      let currentUser = this.$store.getters["useUserStore/getUserInfo"]
			localStorage.setItem('identity_' + currentUser.id, identity);
			this.$router.push('/home/page');
		}
	}
};
</script>

<style lang="less" scoped>
.item-logo {
  width: 8em !important;
  height: 2em !important;
  margin-top: 40px;
}
.login{
  padding-top: 60px;
}
.identity-center-width-img {
	text-align: center;
	img {
		margin: 50px 0 36px 0;
	}
}
.login-center-width-welcome {
	margin-bottom: 40px;
}
.identity-center-width-goMoney {
  text-align: center;
	margin-bottom: 40px;
	line-height: 70px;
	width: 300px;
	background: #00a2e6;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}
.b60 {
	height: 60px;
}
.nbottom {
	margin-bottom: 0;
}
.login-center-width-img{
	margin-bottom: 40px;
}
</style>
